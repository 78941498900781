import { makeObservable, action, computed, observable } from 'mobx';

import type { App, IWhereAmIResponseLeaderboard } from '..';

export interface IBattlePassData {
	[key: string]: IBattlePass[];
}

export type TBattlePassProvider = 'self' | 'twitch' | 'telegram' | 'youtube' | 'steam';
export type TBattlePassStatus = 'idle' | 'waiting' | 'success';

export interface IBattlePass {
	conditions: (IBattlePassConditionLink | IBattlePassConditionSubscribe | IBattlePassConditionGoal)[];
	rewards: IBattlePassReward[];
}

export type TBattlePassConditionType = 'join' | 'subscribe' | 'goal';

export interface IBattlePassCondition {
	type: TBattlePassConditionType;
	status: TBattlePassStatus;
	provider: TBattlePassProvider;
}

export interface IBattlePassReward {
	status: 'idle' | 'waiting' | 'success';
	tag: string;
	meta: { [key: string]: string | number };
}

export interface IBattlePassConditionLink extends IBattlePassCondition {}

export interface IBattlePassConditionSubscribe extends IBattlePassCondition {
	providerChannelId: string;
	providerLink: string;
}

export interface IBattlePassConditionGoal extends IBattlePassCondition {
	value: number;
}

export class BattlePassModel {
	@observable
	protected $isInitialized: boolean = false;

	@observable
	protected $data: IBattlePassData = {};

	@computed
	public get isInitialized(): boolean {
		return this.app.models.leaderboard.isInitialized && !!this.$isInitialized;
	}

	@computed
	public get list(): IBattlePass[] {
		return this.$data[this.app.models.leaderboard.currentLeaderboardId.resourceId] ?? [];
	}

	public constructor(public readonly app: App) {
		makeObservable(this);
	}

	@action
	public initialize(data: IWhereAmIResponseLeaderboard[]) {
		this.setData(data);

		this.$isInitialized = true;
	}

	@action
	public setData(data: IWhereAmIResponseLeaderboard[]): this {
		this.$data = data.reduce((result, item) => {
			return {
				...result,
				[item.ID.ResourceID]: [
					...item.MerchandiseGiveaways.map((giveavay) => ({
						conditions: giveavay.When.reduce(
							(result, condition) => {
								return [
									...result,
									...condition.Links.map((link) => ({
										type: 'join' as TBattlePassConditionType,
										provider: link.Provider,
										status: (link.Checked ? 'success' : 'idle') as TBattlePassStatus,
									})),
									...condition.Subscriptions.map((subscription) => ({
										type: 'subscribe' as TBattlePassConditionType,
										provider: subscription.Provider,
										providerChannelId: subscription.ChannelID,
										providerLink: subscription.ChannelLink,
										status: (subscription.Checked ? 'success' : 'idle') as TBattlePassStatus,
									})),
									...(!!condition.PointsThreshold
										? [
												{
													type: 'goal' as TBattlePassConditionType,
													provider: 'self' as TBattlePassProvider,
													status: !!condition.PointsThreshold?.Checked ? 'success' : 'idle',
													value: condition.PointsThreshold.LowerThreshold ?? -1,
												} as IBattlePassConditionGoal,
											]
										: []),
								];
							},
							[] as IBattlePass['conditions']
						),
						rewards: giveavay.Items.reduce(
							(result, reward) => {
								return [
									...result,
									{
										status: {
											NOT_STARTED: 'idle',
											PENDING: 'waiting',
											FULFILLED: 'success',
										}[reward.State] as IBattlePassReward['status'],
										tag: reward.Tag.Tag,
										meta: reward.Tag.Meta,
									},
								];
							},
							[] as IBattlePass['rewards']
						),
					})),
				],
			};
		}, {});

		return this;
	}
}
