import numeral from 'numeral';

numeral.register('locale', 'ru', {
	delimiters: {
		thousands: ' ',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'тыс.',
		million: 'млн.',
		billion: 'млр.',
		trillion: 'трл.',
	},
	ordinal: function (number) {
		return '.';
	},
	currency: {
		symbol: '₽',
	},
});
