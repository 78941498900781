import { makeObservable, action, computed, observable } from 'mobx';

import { sha256 } from 'js-sha256';

import { App, IWhereAmIResponseProfile } from '..';

export interface IUserData {
	id: string;
	username: string;
	email: string;
	profileImageUrl: string;

	authProviders: TUserDataAuthProviders;
	connectedAccounts: TUserDataConnectedAccounts;

	contacts: {
		contacts_name_second: string;
		contacts_name_first: string;
		contacts_name_fathers: string;
		contacts_address_country: string;
		contacts_address_index: string;
		contacts_address_city: string;
		contacts_address_value: string;
		contacts_phone: string;
		contacts_email: string;
		contacts_telegram: string;
	};

	history: IUserDataHistory[];
}

export interface IUserDataHistory {
	type: string;
	campaignId: string;
	integrationId: string;
	date: Date;
	resources: {
		[key: string]: number;
	};
}

export type TUserSocialNetwork = 'twitch' | 'google' | 'telegram' | 'steam';

export type TUserDataSocial = {
	[key in TUserSocialNetwork]: string;
};

export type TUserDataAuthProviders = {
	[key in TUserSocialNetwork]: { type: 'link' | 'script' } & (
		| {
				type: 'link';
				link: string;
				script?: never;
		  }
		| {
				type: 'script';
				link?: never;
				script: {
					src: string;
					data?: Record<string, string>;
				} | null;
		  }
	);
};

export type TUserDataConnectedAccounts = {
	[key in TUserSocialNetwork]?: string | 'connected';
};

export interface IUserHistory {
	points: IUserHistoryPoints & { byDay: { [key: string]: IUserHistoryPoints } };
}

export interface IUserHistoryPoints {
	total: number;
	watching: number;
	activity: number;
}

export class UserModel {
	@observable
	protected $isInitialized: boolean = false;

	@observable
	protected $data: IUserData = {
		id: '',
		username: '',
		email: '',
		profileImageUrl: '',
		authProviders: {
			twitch: {
				type: 'link',
				link: '',
			},
			google: {
				type: 'link',
				link: '',
			},
			steam: {
				type: 'link',
				link: '',
			},
			telegram: {
				type: 'script',
				script: {
					src: '',
				},
			},
		},
		connectedAccounts: {},
		contacts: {
			contacts_name_second: '',
			contacts_name_first: '',
			contacts_name_fathers: '',
			contacts_address_country: '',
			contacts_address_index: '',
			contacts_address_city: '',
			contacts_address_value: '',
			contacts_phone: '',
			contacts_email: '',
			contacts_telegram: '',
		},
		history: [],
	};

	@computed
	public get isInitialized(): boolean {
		return !!this.$isInitialized;
	}

	@computed
	public get isAuthorized(): boolean {
		return !!this.username;
	}

	@computed
	public get id(): string {
		return this.$data?.id || '';
	}

	@computed
	public get username(): string {
		return this.$data?.username || '';
	}

	@computed
	public get email(): string {
		return this.$data?.email || '';
	}

	@computed
	public get profileImageUrl(): string {
		if (!!this.$data?.profileImageUrl) {
			return this.$data.profileImageUrl;
		}

		if (!!this.email) {
			return [
				['https://gravatar.com/avatar', sha256(this.email)].join('/'),
				Object.entries({
					s: 150,
					d: 'robohash',
					r: 'pg',
				})
					.map((pair) => pair.map((data) => encodeURIComponent(data)).join('='))
					.join('&'),
			].join('?');
		}

		return '';
	}

	@computed
	public get authLinks(): { login: string; logout: string } {
		const link = [
			window.location.protocol,
			'//',
			this.app.hostname || window.location.hostname,
			(this.$data?.authProviders.twitch.link || '').replace(/\/\//g, '/'),
		].join('');

		return {
			login: link,
			logout: link.replace('/redirect/', '/logout/'),
		};
	}

	@computed
	public get socialAuthProviders(): Partial<TUserDataAuthProviders> {
		return Object.entries(this.$data.authProviders).reduce((result, [network, provider]) => {
			if (!(provider.link || provider.script)) {
				return result;
			}

			return {
				...result,
				[network]: {
					...provider,
					link: !!provider.link
						? [
								window.location.protocol,
								'//',
								this.app.hostname || window.location.hostname,
								(provider.link || '').replace(/\/\//g, '/'),
							].join('')
						: null,
				},
			};
		}, {});
	}

	@computed
	public get connectedAccounts(): Partial<TUserDataConnectedAccounts> {
		return { ...this.$data.connectedAccounts };
	}

	@computed
	public get contacts(): IUserData['contacts'] {
		return {
			...this.$data.contacts,
			contacts_email: this.$data.contacts.contacts_email || this.email,
			contacts_telegram: (this.$data.contacts.contacts_telegram || this.connectedAccounts.telegram) ?? '',
		};
	}

	@computed
	public get history(): IUserHistory | null {
		const { intl, leaderboard } = this.app.models;
		const resourceId = leaderboard.currentLeaderboardId?.resourceId || leaderboard.leaderboards[0]?.resourceId;

		if (!resourceId) {
			return null;
		}

		const points = this.$data.history.reduce(
			(result: IUserHistory['points'], entry) => {
				const day = intl.formatDate(entry.date, 'P');
				const points = entry.resources[resourceId] ?? 0;

				const isWatching = ['background-collector'].includes(entry.type);

				if (!(day in result.byDay)) {
					result.byDay[day] = {
						total: 0,
						watching: 0,
						activity: 0,
					};
				}

				result.total += points;
				result[isWatching ? 'watching' : 'activity'] += points;

				(result.byDay[day] as IUserHistoryPoints).total += points;
				(result.byDay[day] as IUserHistoryPoints)[isWatching ? 'watching' : 'activity'] += points;

				return result;
			},
			{
				total: 0,
				watching: 0,
				activity: 0,
				byDay: {},
			} as IUserHistory['points']
		);

		return { points };
	}

	public constructor(public readonly app: App) {
		makeObservable(this);
	}

	@action
	public initialize(data: IUserData): this {
		this.$data = data;

		this.$isInitialized = true;

		return this;
	}

	@action
	public setContacts(meta: IWhereAmIResponseProfile['Meta']): this {
		this.$data.contacts = Object.entries(meta).reduce(
			(result, [key, value]) => {
				if (!key.startsWith('contacts_')) {
					return result;
				}

				return { ...result, [key]: value };
			},
			{} as IUserData['contacts']
		);

		return this;
	}
}
