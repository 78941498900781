import { makeObservable, action, computed, observable } from 'mobx';

import _get from 'lodash/get';

import numeral from 'numeral';

import { format as dateFNS_format, type FormatDateOptions } from 'date-fns';
import { enUS as dateFNS__locale_en, ru as dateFns__locale_ru } from 'date-fns/locale';

import { App } from 'app';

import './numeral';

export type TIntlLocale = 'en' | 'ru';

export interface IIntlMessages {
	[key: string]: string;
}

export interface IIntlLoader {
	key: string;
	messages: {
		[key in TIntlLocale]: () => Promise<{ default: IIntlMessages }>;
	};
}

export class IntlModel {
	@observable
	protected $isInitialized: boolean = false;

	@observable
	protected $locale: TIntlLocale = 'ru';

	@observable
	protected $messages: IIntlMessages = {};

	@observable
	protected $loaders: Set<IIntlLoader> = new Set();

	@computed
	public get isInitialized(): boolean {
		return this.$isInitialized;
	}

	@computed
	public get locale(): TIntlLocale {
		return this.$locale;
	}

	public get defaultLocale(): TIntlLocale {
		return 'ru';
	}

	@computed
	public get props(): { locale: TIntlLocale; defaultLocale: TIntlLocale; messages: IIntlMessages } {
		return {
			locale: this.locale,
			defaultLocale: this.defaultLocale,
			messages: this.$messages,
		};
	}

	public constructor(public readonly app: App) {
		makeObservable(this);
	}

	@action
	public initialize(): this {
		this.$load()
			.then(
				action((messages) => {
					this.$messages = messages;
				})
			)
			.finally(
				action(() => {
					this.$isInitialized = true;
				})
			);

		numeral.locale(this.locale);

		return this;
	}

	@action
	public setLocale(locale: TIntlLocale): this {
		this.$locale = locale;

		this.$load().then(
			action((messages) => {
				this.$messages = messages;
			})
		);

		numeral.locale(locale);

		return this;
	}

	@action
	public registerLoader(loader: IIntlLoader): this {
		this.$loaders.add(loader);

		return this;
	}

	protected $load(): Promise<IIntlMessages> {
		return Promise.all(
			Array.from(this.$loaders).map((loader) =>
				(loader.messages[this.locale] || loader.messages[this.defaultLocale])?.()?.then((data) =>
					Object.entries(data.default).reduce(
						(result, [k, v]) => ({
							...result,
							[[loader.key, k].join('.')]: v,
						}),
						{}
					)
				)
			)
		).then((data) => data.reduce((result, chunk) => ({ ...result, ...chunk }), {}));
	}

	public getLocalizedValue(value: string | Record<string, string> | undefined): string {
		return typeof value === 'string' ? value : _get(value, this.locale, '');
	}

	public formatNumber(number: number, format: string): string {
		numeral.locale(this.locale);

		return numeral(number).format(format);
	}

	public formatDate(
		date: string | number | Date,
		format: string,
		parameters?: Omit<FormatDateOptions, 'locale'>
	): string {
		const locales = {
			en: dateFNS__locale_en,
			ru: dateFns__locale_ru,
		};

		return dateFNS_format(date, format, {
			...parameters,
			locale: locales[this.locale] || locales[this.defaultLocale],
		});
	}
}
