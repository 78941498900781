import { makeObservable, action, computed, observable } from 'mobx';

import type { LeaderboardModel } from './';

export interface ILeaderboardGift {
	id: string;
	items: ILeaderboardGiftItem[];
	position: [number, number?];
}

export interface ILeaderboardGiftItem {
	id: string;
	title: string;
	description?: string;
	image?: string;
	amount: number;
}

export class LeaderboardsGiftsModel {
	@observable
	protected $data: ILeaderboardGift[] = [];

	@computed
	public get list(): ILeaderboardGift[] & { title?: string } {
		return this.$data
			.toSorted((a, b) => {
				const posA = Array.isArray(a.position) ? a.position[0] : a.position;
				const posB = Array.isArray(b.position) ? b.position[0] : b.position;

				return posA - posB;
			})
			.map((item, index) => ({
				...item,
				title:
					item.items.length === 1
						? item.items[0]?.title
						: [
								item.items[index % (item.items.length - 1)]?.title,
								`или ещё один из ${item.items.length - 1} вариантов`,
							].join(' '),
			}));
	}

	public constructor(public readonly leaderboard: LeaderboardModel) {
		makeObservable(this);
	}

	@action
	public reset(): this {
		this.$data = [];

		return this;
	}

	@action
	public setData(data: ILeaderboardGift[]): this {
		this.$data = data;

		return this;
	}
}
