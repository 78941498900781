import { makeObservable, action, computed, observable } from 'mobx';

import { API } from 'src/lib';

import { IWhereAmIResponse } from './types';

import {
	IntlModel,
	BattlePassModel,
	GiveawaysModel,
	type IGiveawaysDataItem,
	LeaderboardModel,
	UserModel,
	IUserDataHistory,
	IUserData,
} from './models';

export class App {
	@observable
	protected $isInitialized: boolean = false;

	@observable
	protected $hostname: string = HOSTNAME || window.location.hostname;

	public readonly api: {
		leaderboardLanding: API;
		leaderboardPublic: API;
		crm: API;
	} = {
		leaderboardLanding: new API(
			`https://${[API_HOSTNAME, PREFIX].filter(Boolean).join('/')}/twirp/brandis.leaderboards.api.LeaderboardsAPI`
		),
		leaderboardPublic: new API(`https://${LEADERBOARD_API_HOSTNAME}/twirp/brandis.reactor.LeaderBoardPublicAPI`),
		crm: new API(`https://${CRM_API_HOSTNAME}/${ORGANIZATION_ID}/api`),
	};

	public readonly models: {
		intl: IntlModel;
		battlePass: BattlePassModel;
		giveaways: GiveawaysModel;
		leaderboard: LeaderboardModel;
		user: UserModel;
	} = {
		intl: new IntlModel(this),
		battlePass: new BattlePassModel(this),
		giveaways: new GiveawaysModel(this),
		leaderboard: new LeaderboardModel(this),
		user: new UserModel(this),
	};

	@computed
	public get isInitialized(): boolean {
		return this.$isInitialized && Object.values(this.models).every((model) => model.isInitialized);
	}

	@computed
	public get hostname(): string {
		return this.$hostname;
	}

	public constructor() {
		makeObservable(this);
	}

	public initialize(): this {
		this.models.intl.initialize();

		this.api.leaderboardLanding
			.request<IWhereAmIResponse>('WhereAmI')
			.then(this._handleWhereAmIResponse)
			.finally(this._handleInitializeComplete);

		return this;
	}

	private _handleWhereAmIResponse = action((data: IWhereAmIResponse) => {
		this.$hostname = data?.EnvPlace?.Domain || window.location.hostname;

		this.models.leaderboard.initialize();

		this.models.battlePass.initialize(data.Leaderboards.List ?? []);

		this.models.user.initialize({
			id: data.Profile?.Stored?.ID?.ProfileID?.ID || '',
			username: data.Profile?.Username || '',
			email: data.Profile?.Email || '',
			profileImageUrl: data.Profile?.ProfilePic || '',
			authProviders: ((actual, deprecated) => {
				if (!!actual) {
					return {
						twitch: { type: 'link', link: actual?.twitch?.Link || '' },
						google: { type: 'link', link: actual?.google?.Link || '' },
						telegram: {
							type: 'script',
							script: { src: actual?.telegram?.Script || '', data: actual?.telegram?.Data },
						},
						steam: { type: 'link', link: actual?.steam?.Link || '' },
					};
				}

				return {
					twitch: { type: 'link', link: deprecated?.twitch || '' },
					google: { type: 'link', link: deprecated?.google || '' },
					telegram: { type: 'link', link: deprecated?.telegram || '' },
					steam: { type: 'link', link: deprecated?.steam || '' },
				};
			})(data.IdentityProvidersConfigs?.Identities, data.IdentityProviders?.Identities),
			connectedAccounts: {
				...(typeof data.Profile?.ConnectedAccounts?.twitch === 'string'
					? { twitch: data.Profile.ConnectedAccounts.twitch || 'connected' }
					: {}),
				...(typeof data.Profile?.ConnectedAccounts?.google === 'string'
					? { google: data.Profile.ConnectedAccounts.google || 'connected' }
					: {}),
				...(typeof data.Profile?.ConnectedAccounts?.telegram === 'string'
					? { telegram: data.Profile.ConnectedAccounts.telegram || 'connected' }
					: {}),
				...(typeof data.Profile?.ConnectedAccounts?.steam === 'string'
					? { steam: data.Profile.ConnectedAccounts.steam || 'connected' }
					: {}),
			},
			contacts: Object.entries(data.Profile?.Meta ?? {}).reduce(
				(result, [key, value]) => {
					if (!key.startsWith('contacts_')) {
						return result;
					}

					return {
						...result,
						[key]: value,
					};
				},
				{} as IUserData['contacts']
			),
			history: [
				...Object.entries(data?.Profile?.Stored?.FromMatches ?? {})
					.filter((entry) => !!entry[0])
					.reduce((result, entry) => {
						return [
							...result,
							...(entry[1]?.Day ?? []).map((day) => {
								return {
									campaignId: day.Data.CampaignID,
									integrationId: day.Data.IntegrationID,
									type: day.IntegrationType,
									date: new Date(day.Data.At),
									resources: Object.entries(day.Data.Resources).reduce(
										(result, [resourceId, values]) => ({
											...result,
											[resourceId]: values.Amount - values.Spent,
										}),
										{}
									),
								};
							}),
						];
					}, [] as IUserDataHistory[]),
				...Object.entries(data?.Profile?.Stored?.FromSources ?? {})
					.filter((entry) => !!entry[0])
					.reduce((result, entry) => {
						return [
							...result,
							{
								campaignId: entry[1].CampaignID,
								integrationId: entry[1].IntegrationID,
								type: entry[0],
								date: new Date(entry[1].At || Date.now()),
								resources: Object.entries(entry[1].Resources).reduce(
									(result, [resourceId, values]) => ({
										...result,
										[resourceId]: values.Amount - values.Spent,
									}),
									{}
								),
							},
						];
					}, [] as IUserDataHistory[]),
			],
		});

		this.models.giveaways.initialize({
			list: (data.Profile?.Stored?.Merged?.Tags || []).reduce((result, tag) => {
				const itemId = tag.Meta?.item || tag.Tag || tag.Meta.giftid;
				const resourceId = tag.Meta.resource;

				if (!(itemId && resourceId)) {
					return result;
				}

				return [
					...result,
					{
						itemId,
						type: itemId.indexOf('freebet') >= 0 ? 'digital' : 'unknown',
						campaignId: tag.Meta.campaign,
						resourceId,
						title: tag.Meta.title,
						media: tag.Meta.media,
						code: tag.Meta.code,
						dateStart: new Date(tag.ActiveSince || Date.now()),
					} as IGiveawaysDataItem,
				];
			}, [] as IGiveawaysDataItem[]),
		});
	});

	private _handleInitializeComplete = action(() => {
		this.$isInitialized = true;
	});
}

export * from './models';
export * from './types';

export const app = new App();

export default app;
